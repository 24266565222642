import { FORM_SERVIDOR, LISTAGEM_SERVIDOR, MODAL_SERVIDOR } from "../config/types";
import Servidor from "../models/Servidor";

export function servidor(
    state = {
        lista : [],
        formulario: new Servidor(),
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_SERVIDOR){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_SERVIDOR){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_SERVIDOR){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}