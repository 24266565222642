import { FORM_ORIENTACAO, LISTAGEM_ORIENTACAO, MODAL_ORIENTACAO } from "../config/types";
import Orientacao from "../models/Orientacao";

export function orientacao(
    state = {
        lista : [],
        formulario: new Orientacao(),
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_ORIENTACAO){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_ORIENTACAO){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_ORIENTACAO){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}