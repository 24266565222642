import { FORM_ESCRITORIO, LISTAGEM_ESCRITORIO, MODAL_ESCRITORIO } from "../config/types";
import Escritorio from "../models/Escritorio";

export function escritorio(
    state = {
        lista : [],
        formulario: new Escritorio(),
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_ESCRITORIO){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_ESCRITORIO){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_ESCRITORIO){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}