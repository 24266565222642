import { LISTAGEM_EMATER } from "../config/types";

export function emater(
    state = {
        lista : []
    }, action){
    
        if(action.type === LISTAGEM_EMATER){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        return state
}