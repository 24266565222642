export default class Projeto{

    constructor(id = "", emater_id = "", nome="", sigla="", objetivo="", 
    inicio="", fim="", condicao_participacao="", descricao="", observacao=""){
        this.id = id
        this.emater_id = emater_id
        this.nome = nome
        this.sigla = sigla
        this.objetivo = objetivo
        this.inicio = inicio
        this.fim = fim
        this.condicao_participacao = condicao_participacao
        this.descricao = descricao
        this.observacao = observacao
        this.regioes = []
        this.servidores = []
        this.familias = []
    }

}