import { FORM_TIPO_SITUACAO, LISTAGEM_TIPO_SITUACAO, MODAL_TIPO_SITUACAO } from "../config/types";
import TipoSituacao from "../models/TipoSituacao";

export function tipoSituacao(
    state = {
        lista: [],
        formulario: new TipoSituacao(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_TIPO_SITUACAO) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_TIPO_SITUACAO) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_TIPO_SITUACAO) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}