import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux'

import App from './components/App';

import * as serviceWorker from './serviceWorker';

import { utils } from './reducers/utils'
import { tiposAcao } from './reducers/tiposAcao'
import { regiao } from './reducers/regiao'
import { regional } from './reducers/regional'
import { cidade } from './reducers/cidade'
import { emater } from './reducers/emater'
import { escritorio } from './reducers/escritorio'
import { servidor } from './reducers/servidor'
import { perfil } from './reducers/perfil'
import { cargo } from './reducers/cargo'
import { projeto } from './reducers/projeto'
import { familia } from './reducers/familia'
import { acaoFamilia } from './reducers/acaoFamilia'
import { dash } from './reducers/dash'
import { atividadeProdutiva } from './reducers/atividadeProdutiva'
import { tipoSituacao } from './reducers/tipoSituacao'
import { situacao } from './reducers/situacao'
import { comunidade } from './reducers/comunidade'
import { orientacao } from './reducers/orientacao'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'


const persistConfig = {
    key: '_emater_pers_2',
    storage,
    whitelist: ['tiposAcao']
}


const reducers = combineReducers({ tiposAcao, regiao, cidade, regional, emater,
    escritorio,servidor, perfil, projeto, familia, acaoFamilia, dash, cargo,
    atividadeProdutiva, tipoSituacao, situacao, comunidade, orientacao})

const persistReducers = persistReducer(persistConfig, reducers)

const store = createStore(persistReducers, applyMiddleware(thunkMiddleware))

const persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <App />
            </HashRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
