export default class Familia{

    constructor(id = "", cidade_id = "", nome_responsavel="", telefone="", cpf_responsavel="", 
    rg_responsavel="", endereco="", localidade="", membros_familia="", lat="", lng="",
    data_cadastro="", situacao_dap="", data_vencimento_dap="", email="", dap= "", atividades_produtivas = [], key_cartao=""){
        this.id = id
        this.cidade_id = cidade_id
        this.nome_responsavel = nome_responsavel
        this.telefone = telefone
        this.cpf_responsavel = cpf_responsavel
        this.rg_responsavel = rg_responsavel
        this.endereco = endereco
        this.localidade = localidade
        this.membros_familia = membros_familia
        this.data_cadastro = data_cadastro
        this.situacao_dap = situacao_dap
        this.data_vencimento_dap = data_vencimento_dap
        this.email = email
        this.dap = dap
        this.atividades_produtivas = atividades_produtivas
        this.key_cartao = key_cartao
    }

}