export default class Escritorio{

    constructor(id = "", emater_id = "", regional_id="", nome="", endereco="", territorio_sede=""){
        this.id = id
        this.emater_id = emater_id
        this.regional_id = regional_id
        this.nome = nome
        this.endereco = endereco
        this.territorio_sede = territorio_sede
    }

}