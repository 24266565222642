import { DASHBOARD } from "../config/types";

export function dash(state={dash:undefined},action){


    if(action.type === DASHBOARD){
        state.dash = action.dash
        return Object.assign({}, state)
    }

    return state
}