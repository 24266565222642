import { LISTAGEM_CARGOS } from "../config/types";

export function cargo(
    state = {
        lista : []
    }, action){
    
        if(action.type === LISTAGEM_CARGOS){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        return state
}