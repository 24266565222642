// in src/restricted.js
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DASHBOARD, FORMULARIO_ACAO_FAMILIA, FORMULARIO_ATIVIDADE_PRODUTIVA, FORMULARIO_COMUNIDADE, FORMULARIO_ESCRITORIO, FORMULARIO_FAMILIA, FORMULARIO_ORIENTACAO, FORMULARIO_PROJETO, FORMULARIO_REGIAO, FORMULARIO_REGIONAL, FORMULARIO_SERVIDOR, FORMULARIO_SITUACAO, FORMULARIO_TIPO_ACAO, FORMULARIO_TIPO_SITUACAO, LISTAGEM_ACAO_FAMILIA, LISTAGEM_ATIVIDADE_PRODUTIVA, LISTAGEM_COMUNIDADE, LISTAGEM_ESCRITORIO, LISTAGEM_FAMILIA, LISTAGEM_ORIENTACAO, LISTAGEM_PROJETO, LISTAGEM_REGIAO, LISTAGEM_REGIONAL, LISTAGEM_SERVIDOR, LISTAGEM_SITUACAO, LISTAGEM_TIPO_ACAO, LISTAGEM_TIPO_SITUACAO, ROOT, LISTAGEM_PERFIL, FORMULARIO_PERFIL, RELATORIOS } from '../config/HashRoutes';

/**
 * Higher-order component (HOC) to wrap restricted pages
 */
export const BaseComponent = (Comp) => {

    class Restricted extends Component {

        permissao = false

        componentWillMount() {
            this.checkAuthentication(this.props);
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.location !== this.props.location) {
                this.checkAuthentication(nextProps);
            }
        }

        getRota(id){
            switch(id){
                case 1: return DASHBOARD
                case 2: return LISTAGEM_TIPO_ACAO
                case 3: return LISTAGEM_REGIAO
                case 4: return LISTAGEM_REGIONAL
                case 5: return LISTAGEM_ESCRITORIO
                case 6: return LISTAGEM_SERVIDOR
                case 7: return LISTAGEM_PROJETO
                case 8: return LISTAGEM_FAMILIA
                case 9: return LISTAGEM_ACAO_FAMILIA
                case 10: return LISTAGEM_ATIVIDADE_PRODUTIVA
                case 11: return LISTAGEM_TIPO_SITUACAO
                case 12: return LISTAGEM_SITUACAO
                case 13: return LISTAGEM_COMUNIDADE
                case 14: return LISTAGEM_ORIENTACAO
            }
            return ROOT
        }

        getRotaId(id){
            switch(id){
                case DASHBOARD : return 1
                case LISTAGEM_TIPO_ACAO : return 2
                case FORMULARIO_TIPO_ACAO:  return 2
                case LISTAGEM_REGIAO : return 3
                case FORMULARIO_REGIAO: return 3
                case LISTAGEM_REGIONAL : return 4
                case FORMULARIO_REGIONAL: return 4
                case LISTAGEM_ESCRITORIO : return 5
                case FORMULARIO_ESCRITORIO: return 5
                case LISTAGEM_SERVIDOR : return 6
                case FORMULARIO_SERVIDOR: return 6
                case LISTAGEM_PROJETO : return 7
                case FORMULARIO_PROJETO: return 7
                case LISTAGEM_FAMILIA : return 8
                case FORMULARIO_FAMILIA: return 8
                case LISTAGEM_ACAO_FAMILIA : return 9
                case FORMULARIO_ACAO_FAMILIA: return 9
                case LISTAGEM_ATIVIDADE_PRODUTIVA: return 10
                case FORMULARIO_ATIVIDADE_PRODUTIVA: return 10
                case LISTAGEM_TIPO_SITUACAO: return 11
                case FORMULARIO_TIPO_SITUACAO: return 11
                case LISTAGEM_SITUACAO: return 12
                case FORMULARIO_SITUACAO: return 12
                case LISTAGEM_COMUNIDADE: return 13
                case FORMULARIO_COMUNIDADE: return 13
                case LISTAGEM_ORIENTACAO: return 14
                case FORMULARIO_ORIENTACAO: return 14
                case RELATORIOS: return 15
                case LISTAGEM_PERFIL: return -1
                case FORMULARIO_PERFIL: return -1
            }
            return ROOT
        }

        checkAuthentication(params) {
            const { history } = params;
            ////console.log(history)
            this.permissao = true
            if (localStorage.getItem('_emater_user') === null && params.location.pathname !== '/') {
                //return replace('/')
                    history.replace(ROOT)
            } else if (localStorage.getItem('_emater_user') && (params.location.pathname === '/' || params.location.pathname === '/painel')) {
                //return replace('/painel')

                let user = JSON.parse(window.atob(localStorage.getItem("_emater_user")))

                user.perfil.funcionalidades.sort((a,b) => (a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0)
                if(user.perfil.funcionalidades.length > 0){
                    let rota = this.getRota(user.perfil.funcionalidades[0].id)
                    
                    history.replace(rota)
                }else{
                    history.replace(ROOT)
                }
               
            }else if(localStorage.getItem('_emater_user')){
                console.log('chegou aqui')

                let user = JSON.parse(window.atob(localStorage.getItem("_emater_user")))
                let id = this.getRotaId(params.location.pathname)

                if(id === 15){
                    this.permissao = true
                    return
                }

                if(id < 0){

                    this.permissao = false
                    if(user.perfil.id === 1) this.permissao = true
                    if(!this.permissao) history.goBack()

                }else{

                    this.permissao = false
                    user.perfil.funcionalidades.forEach(f => {
                        if(f.id === id) this.permissao = true
                    })
    
                    if(!this.permissao) history.goBack()
                }

            }
        }

        render() {
            
            if(this.permissao)
                return (<Comp />)
            else
                return (<div></div>)
            
        }
    }
    return withRouter(Restricted);
}