export default class AcaoFamilia{

    constructor(id = "", projeto_id = "", familia_id="", servidor_id="", tipo_acao_id="", 
    atividade="", lat ="", lng="", data_acao="", registro_acao="", familias=[]){
        this.id = id
        this.projeto_id = projeto_id
        this.familia_id = familia_id
        this.servidor_id = servidor_id
        this.tipo_acao_id = tipo_acao_id
        this.atividade = atividade
        this.lat = lat
        this.lng = lng
        this.data_acao = data_acao
        this.registro_acao = registro_acao
        this.familias = familias
    }

}