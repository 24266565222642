import { FORM_COMUNIDADE, LISTAGEM_COMUNIDADE, MODAL_COMUNIDADE } from "../config/types";
import Comunidade from "../models/Comunidade";

export function comunidade(
    state = {
        lista: [],
        formulario: new Comunidade(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_COMUNIDADE) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_COMUNIDADE) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_COMUNIDADE) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}