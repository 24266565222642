
import React, { Suspense, lazy } from 'react'

import { Switch, Route } from 'react-router-dom'
import { BaseComponent } from '../validators/RouteValidator'
import { ROOT, TEMPLATE} from './HashRoutes';

const Login = lazy(() => import('../components/Login'));
const Template = lazy(() => import('../components/Template'));
const NotFound = lazy(() => import('../components/NotFound'));

const Routes = () => (
    <main>
        <Suspense fallback={<div style={{ margin: 'auto', paddingTop: '100px', width:'300px', textAlign:'center' }}>
          <br/><p style={{fontWeight:'bold', fontSize:'30px', fontFamily:'monospace'}}>SADA Protótipo</p>
        </div>}>
            <Switch>
                <Route exact path={ROOT} component={BaseComponent(Login)}  />
                <Route path={TEMPLATE} component={BaseComponent(Template)}  />
                <Route exact component={BaseComponent(NotFound)} />
            </Switch>
        </Suspense>
    </main>
)

export default Routes