export default class Servidor{

    constructor(id = "", escritorio_id = "", perfil_id="", cidade_id="", nome="", 
    email="", cpf="", rg="", endereco="", localidade="",
    telefone = "", matricula = "", admissao="", lotacao="", cargo_id=""){
        this.id = id
        this.escritorio_id = escritorio_id
        this.perfil_id = perfil_id
        this.cidade_id = cidade_id
        this.nome = nome
        this.email = email
        this.cpf = cpf
        this.rg = rg
        this.endereco = endereco
        this.localidade = localidade
        this.telefone = telefone
        this.matricula = matricula
        this.admissao = admissao
        this.lotacao = lotacao
        this.cargo_id = cargo_id
    }

}