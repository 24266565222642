import { FORM_REGIAO, LISTAGEM_REGIAO, MODAL_REGIAO } from "../config/types";
import Regiao from "../models/Regiao";

export function regiao(
    state = {
        lista : [],
        formulario: new Regiao(),
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_REGIAO){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_REGIAO){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_REGIAO){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}