import { FORM_ATIVIDADE_PRODUTIVA, LISTAGEM_ATIVIDADE_PRODUTIVA, MODAL_ATIVIDADE_PRODUTIVA } from "../config/types";
import AtividadeProdutiva from "../models/AtividadeProdutiva";

export function atividadeProdutiva(
    state = {
        lista: [],
        formulario: new AtividadeProdutiva(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_ATIVIDADE_PRODUTIVA) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_ATIVIDADE_PRODUTIVA) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_ATIVIDADE_PRODUTIVA) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}