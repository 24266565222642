export default class TipoSituacao{

    constructor(id = "", nome = "", entidade = "", cod_entidade ="",  multiplo = 0){
        this.id = id
        this.nome = nome
        this.multiplo = multiplo
        this.entidade = entidade
        this.cod_entidade = cod_entidade
    }

}