import { FORM_PROJETO, LISTAGEM_PROJETO, MODAL_PROJETO } from "../config/types";
import Projeto from "../models/Projeto";

export function projeto(
    state = {
        lista: [],
        formulario: new Projeto(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_PROJETO) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_PROJETO) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_PROJETO) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}