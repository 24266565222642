import { LISTAGEM_ESTADO, LISTAGEM_CIDADES } from "../config/types";

export function cidade(state ={
    estados: [],
    cidades: []
},action){

    if(action.type === LISTAGEM_ESTADO){
        state.estados = action.estados
        return Object.assign({}, state)
    }

    if(action.type === LISTAGEM_CIDADES){
        state.cidades = action.cidades
        return Object.assign({}, state)
    }

    return state
}