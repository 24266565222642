import { FORM_SITUACAO, LISTAGEM_SITUACAO, MODAL_SITUACAO } from "../config/types";
import Situacao from "../models/Situacao";

export function situacao(
    state = {
        lista: [],
        formulario: new Situacao(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_SITUACAO) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_SITUACAO) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_SITUACAO) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}