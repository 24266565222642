import { LISTAGEM_PERFIL, FORM_PERFIL, LISTAGEM_FUNCIONALIDADE, MODAL_PERFIL } from "../config/types";
import Perfil from "../models/Perfil";

export function perfil(
    state = {
        lista : [],
        formulario: new Perfil(),
        funcionalidades: [],
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_PERFIL){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_PERFIL){
            state.formulario = action.obj
            return Object.assign({}, state)
        }

        if(action.type === LISTAGEM_FUNCIONALIDADE){
            state.funcionalidades = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
        if(action.type === MODAL_PERFIL){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}