
//TIPOS ACAO
export const LISTAGEM_TIPOS_ACAO = 'LISTAGEM_TIPOS_ACAO'
export const FORM_TIPO_ACAO = 'FORM_TIPO_ACAO'
export const TIPO_ACAO_SELECIONADO = 'TIPO_ACAO_SELECIONADO'
export const MODAL_TIPO_ACAO = 'MODAL_TIPO_ACAO'
export const COUNTS_ACAO = 'COUNTS_ACAO'


//REGIOES
export const LISTAGEM_REGIAO = 'LISTAGEM_REGIAO'
export const FORM_REGIAO = 'FORM_REGIAO'
export const MODAL_REGIAO = 'MODAL_REGIAO'
export const LISTAGEM_ESTADO = 'LISTAGEM_ESTADO'
export const LISTAGEM_CIDADES = 'LISTAGEM_CIDADES'


//REGIOES
export const LISTAGEM_REGIONAL = 'LISTAGEM_REGIONAL'
export const FORM_REGIONAL = 'FORM_REGIONAL'
export const MODAL_REGIONAL = 'MODAL_REGIONAL'
export const LISTAGEM_EMATER = 'LISTAGEM_EMATER'

//REGIOES
export const LISTAGEM_ESCRITORIO = 'LISTAGEM_ESCRITORIO'
export const FORM_ESCRITORIO = 'FORM_ESCRITORIO'
export const MODAL_ESCRITORIO = 'MODAL_ESCRITORIO'

//REGIOES
export const LISTAGEM_SERVIDOR = 'LISTAGEM_SERVIDOR'
export const FORM_SERVIDOR = 'FORM_SERVIDOR'
export const MODAL_SERVIDOR = 'MODAL_SERVIDOR'
export const LISTAGEM_PERFIL = 'LISTAGEM_PERFIL'
export const LISTAGEM_FUNCIONALIDADE = 'LISTAGEM_FUNCIONALIDADE'
export const FORM_PERFIL = 'FORM_PERFIL'
export const MODAL_PERFIL = 'MODAL_PERFIL'
export const LISTAGEM_CARGOS = 'LISTAGEM_CARGOS'

//REGIOES
export const LISTAGEM_PROJETO = 'LISTAGEM_PROJETO'
export const FORM_PROJETO = 'FORM_PROJETO'
export const MODAL_PROJETO = 'MODAL_PROJETO'

//REGIOES
export const LISTAGEM_FAMILIA = 'LISTAGEM_FAMILIA'
export const FORM_FAMILIA = 'FORM_FAMILIA'
export const MODAL_FAMILIA = 'MODAL_FAMILIA'

//REGIOES
export const LISTAGEM_ACAO_FAMILIA = 'LISTAGEM_ACAO_FAMILIA'
export const FORM_ACAO_FAMILIA = 'FORM_ACAO_FAMILIA'
export const MODAL_ACAO_FAMILIA = 'MODAL_ACAO_FAMILIA'
export const DASHBOARD = 'DASHBOARD'


//ATIVIDADES PRODUTIVAS
export const LISTAGEM_ATIVIDADE_PRODUTIVA = 'LISTAGEM_ATIVIDADE_PRODUTIVA'
export const FORM_ATIVIDADE_PRODUTIVA  = 'FORM_ATIVIDADE_PRODUTIVA'
export const MODAL_ATIVIDADE_PRODUTIVA  = 'MODAL_ATIVIDADE_PRODUTIVA'

//TIPOS DE SITUACOES
export const LISTAGEM_TIPO_SITUACAO = 'LISTAGEM_TIPO_SITUACAO'
export const FORM_TIPO_SITUACAO  = 'FORM_TIPO_SITUACAO'
export const MODAL_TIPO_SITUACAO  = 'MODAL_TIPO_SITUACAO'

//SITUACOES
export const LISTAGEM_SITUACAO = 'LISTAGEM_SITUACAO'
export const FORM_SITUACAO  = 'FORM_SITUACAO'
export const MODAL_SITUACAO = 'MODAL_SITUACAO'

//COMUNIDADES
export const LISTAGEM_COMUNIDADE = 'LISTAGEM_COMUNIDADE'
export const FORM_COMUNIDADE  = 'FORM_COMUNIDADE'
export const MODAL_COMUNIDADE  = 'MODAL_COMUNIDADE'

//ORIENTACOES
export const LISTAGEM_ORIENTACAO = 'LISTAGEM_ORIENTACAO'
export const FORM_ORIENTACAO  = 'FORM_ORIENTACAO'
export const MODAL_ORIENTACAO  = 'MODAL_ORIENTACAO'

