export const ROOT = '/'

export const TEMPLATE = '/painel'


export const DASHBOARD = TEMPLATE + '/visao-geral'


export const LISTAGEM_ESCRITORIO = TEMPLATE + '/escritorios'
export const FORMULARIO_ESCRITORIO = TEMPLATE + '/escritorios/formulario'


export const LISTAGEM_FAMILIA = TEMPLATE + '/familias'
export const FORMULARIO_FAMILIA = TEMPLATE + '/familias/formulario'

export const LISTAGEM_ACAO_FAMILIA = TEMPLATE + '/acoes-familias'
export const FORMULARIO_ACAO_FAMILIA = TEMPLATE + '/acoes-familias/formulario'


export const LISTAGEM_PROJETO = TEMPLATE + '/projetos'
export const FORMULARIO_PROJETO = TEMPLATE + '/projetos/formulario'


export const LISTAGEM_REGIAO = TEMPLATE + '/regioes'
export const FORMULARIO_REGIAO = TEMPLATE + '/regioes/formulario'


export const LISTAGEM_REGIONAL = TEMPLATE + '/regionais'
export const FORMULARIO_REGIONAL = TEMPLATE + '/regionais/formulario'


export const LISTAGEM_SERVIDOR = TEMPLATE + '/servidores'
export const FORMULARIO_SERVIDOR = TEMPLATE + '/servidores/formulario'


export const LISTAGEM_TIPO_ACAO = TEMPLATE + '/tipos-acao'
export const FORMULARIO_TIPO_ACAO = TEMPLATE + '/tipos-acao/formulario'

export const LISTAGEM_ATIVIDADE_PRODUTIVA = TEMPLATE + '/atividades-produtivas'
export const FORMULARIO_ATIVIDADE_PRODUTIVA = TEMPLATE + '/atividades-produtivas/formulario'

export const LISTAGEM_TIPO_SITUACAO = TEMPLATE + '/tipos-situacoes'
export const FORMULARIO_TIPO_SITUACAO = TEMPLATE + '/tipos-situacoes/formulario'

export const LISTAGEM_SITUACAO = TEMPLATE + '/situacoes'
export const FORMULARIO_SITUACAO = TEMPLATE + '/situacoes/formulario'

export const LISTAGEM_COMUNIDADE = TEMPLATE + '/comunidades'
export const FORMULARIO_COMUNIDADE = TEMPLATE + '/comunidades/formulario'

export const RELATORIOS = TEMPLATE + '/relatorios'

export const LISTAGEM_ORIENTACAO = TEMPLATE + '/orientacoes'
export const FORMULARIO_ORIENTACAO = TEMPLATE + '/orientacoes/formulario'

export const LISTAGEM_PERFIL = TEMPLATE + '/perfil'
export const FORMULARIO_PERFIL = TEMPLATE + '/perfil/formulario'