import { FORM_FAMILIA, LISTAGEM_FAMILIA, MODAL_FAMILIA } from "../config/types";
import Familia from "../models/Familia";

export function familia(
    state = {
        lista: [],
        formulario: new Familia(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_FAMILIA) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_FAMILIA) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_FAMILIA) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}