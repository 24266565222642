export default class Regional{

    constructor(id = "", emater_id = "", nome="", regiao_id="", localizacao="", divisao_territorial=""){
        this.id = id
        this.nome = nome
        this.emater_id = emater_id
        this.regiao_id = regiao_id
        this.localizacao = localizacao
        this.divisao_territorial = divisao_territorial
    }

}