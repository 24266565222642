export default class Regiao{

    constructor(id = "", nome = "", codigo = "", descricao="", cidades =[]){
        this.id = id
        this.nome = nome
        this.codigo = codigo
        this.descricao = descricao
        this.cidades = cidades
    }

}