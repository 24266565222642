import { FORM_ACAO_FAMILIA, LISTAGEM_ACAO_FAMILIA, MODAL_ACAO_FAMILIA } from "../config/types";
import AcaoFamilia from "../models/AcaoFamilia";

export function acaoFamilia(
    state = {
        lista: [],
        formulario: new AcaoFamilia(),
        selecionado: undefined
    }, action) {

    if (action.type === LISTAGEM_ACAO_FAMILIA) {
        state.lista = action.lista
        //Utils.console(state)
        return Object.assign({}, state)
    }

    if (action.type === FORM_ACAO_FAMILIA) {
        state.formulario = action.obj
        return Object.assign({}, state)
    }


    if (action.type === MODAL_ACAO_FAMILIA) {
        state.selecionado = action.obj
        return Object.assign({}, state)
    }

    return state
}