import TipoAcao from "../models/TipoAcao";
import { LISTAGEM_TIPOS_ACAO, FORM_TIPO_ACAO, MODAL_TIPO_ACAO, COUNTS_ACAO } from "../config/types";

export function tiposAcao(
    state = {
        lista : [],
        formulario: new TipoAcao(),
        selecionado : undefined,
        counts: [],
    }, action){
    
        if(action.type === LISTAGEM_TIPOS_ACAO){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_TIPO_ACAO){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_TIPO_ACAO){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }

        if(action.type === COUNTS_ACAO){
            state.counts = action.obj
            return Object.assign({}, state)
        }
    
        return state
}