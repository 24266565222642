export default class Perfil{

    constructor(id = "", nome = "",  permissoes= "", app= "", app_nome = ""){
        this.id = id
        this.nome = nome
        this.permissoes = permissoes
        this.app = app
        this.app_nome = app_nome
    }

}