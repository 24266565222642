import { FORM_REGIONAL, LISTAGEM_REGIONAL, MODAL_REGIONAL } from "../config/types";
import Regional from "../models/Regional";

export function regional(
    state = {
        lista : [],
        formulario: new Regional(),
        selecionado : undefined
    }, action){
    
        if(action.type === LISTAGEM_REGIONAL){
            state.lista = action.lista
            //Utils.console(state)
            return Object.assign({}, state)
        }
    
        if(action.type === FORM_REGIONAL){
            state.formulario = action.obj
            return Object.assign({}, state)
        }
    
    
        if(action.type === MODAL_REGIONAL){
            state.selecionado = action.obj        
            return Object.assign({}, state)
        }
    
        return state
}